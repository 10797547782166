import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IBackArrow,
  IRemoveIcon,
  IAttachIcon,
  ILogoWhite,
  IAttachReports,
  IConsultation,
  IDiagnosis,
  IMicIcon,
  INextSteps,
  IPersonalInfo,
  ITranscription,
  IVitals,
  IRemindersIcon,
  IExpandButton,
  ICopyButton
} from "../../../../assets";
import * as Styled from "./style.components";
import {
  AttachRecordsModal,
  ChatWidgetComponent,
  AudioRecordModal,
  AddRecords,
} from "../../../../components/layout/";
import { SnackBar } from "../../../../components/shared";
import {
  callHemeBotAction,
  getSingleRecordAction,
  getPatientProfileAction,
  getRecordTypeAction,
  getObservationTypesAction,
  addObservationAction,
  getTemplateAction
} from "../../../../store/actions";
import { hemebotSelector } from "../../../../store/slice/hemebot.slice";
import { patientProfileSelector } from "../../../../store/slice/patient-profile.slice";
import { generateDocSelector } from "../../../../store/slice/generate-doc.slice";
import { recordTypeSelector } from "../../../../store/slice/record-type.slice";
import { resetState } from "../../../../store/slice/hemebot.slice";
import { resetAiSummaries } from "../../../../store/slice/single-record.slice";
import { patientSelector } from "../../../../store/slice/patient.slice";
import moment from "moment";
import { ShimmerText } from "react-shimmer-effects";
import html2pdf from "html2pdf.js";
import { Loader } from "../../../../components/shared";
import CustomReminderCard from "../custom-reminder-card/index";
import {
  EmptyListMessage,
  RemindersContainer,
} from "../custom-reminder-card/style.components";
import { setShowStatus } from "../../../../store/slice/status-slice";
import TextExpandModal from "../../../../components/layout/text-expand-modal";
import axios from 'axios';

<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.3/html2pdf.bundle.min.js"></script>;

const calculateAge = (dob) => {
  if (!dob) return null;
  const birthDate = moment(dob);
  const age = moment().diff(birthDate, "years");
  return age;
};

const copyToClipboard = (text, dispatch) => {
  navigator.clipboard.writeText(text);
  dispatch(
    setShowStatus({
      severity: "success",
      message: "Copied to clipboard!",
      autoHide: true,
      autoHideIn: 1000,
    })
  );
}

const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const extractNumericValue = (value) => value.replace(/[^0-9.]/g, "");

const formatBloodPressure = (bpString) => {
  const numericString = bpString.replace(/\D/g, "");
  if (numericString.length > 4) {
  const formattedBP = `${numericString.slice(0, 3)}/${numericString.slice(3)}`;
  return formattedBP;
  }
};

const NewEncounter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientProfile = useSelector(patientProfileSelector);
  const [patientName, setPatientName] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [patientHeight, setPatientHeight] = useState("");
  const [patientWeight, setPatientWeight] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [spo2, setSpo2] = useState("");
  const [respiratoryRate, setRespiratoryRate] = useState("");
  const [temperature, setTemperature] = useState("");
  const [randomBloodSugar, setRandomBloodSugar] = useState("");
  const [isGenerateEnabled, setIsGenerateEnabled] = useState(false);
  const [gender, setGender] = useState("");
  const [patientHistory, setPatientHistory] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [physicalExamination, setPhysicalExamination] = useState("");
  const [otherNotes, setOtherNotes] = useState("");
  const [showAddRecordsModal, setShowAddRecordsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [assessmentResponse, setAssessmentResponse] = useState({});
  const [isTranscriptionProgress, setIsTranscriptionProgress] =
    useState("start");
  const [singleRecordResponse, setSingleRecordResponse] = useState([]);
  const [transcriptionResponse, setTranscriptionResponse] = useState("");
  const location = useLocation();
  const [userSentMessage, setUserSentMessage] = useState(false);
  const [assessmentRun, setAssessmentRun] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [systemPrompt, setSystemPrompt] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [shouldOpenDocument, setShouldOpenDocument] = useState(false);
  const aiSummaries =
    useSelector((state) =>
      state.singleRecord.singleRecordObj
        ? state.singleRecord.singleRecordObj.aiSummaries
        : []
    ) || [];
  const documentData = useSelector(generateDocSelector);
  const [showAudioRecordModal, setshowAudioRecordModal] = useState(false);
  const [recordTypesArray, setRecordTypesArray] = useState([]);
  const recordTypes = useSelector((state) => recordTypeSelector(state));
  const [isPdfMode, setIsPdfMode] = useState(false);
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState("");
  const [showSendRecordsModal, setShowSendRecordsModal] = useState(false);
  const [nextSteps, setNextSteps] = useState("");
  const [localreminders, setLocalReminders] = useState([]);
  const summaryRef = useRef();
  const transcriptionRef = useRef();
  const prescriptionRef = useRef();
  const [pdfFiles, setPdfFiles] = useState({
    summary: null,
    transcription: null,
    prescription: null,
  });
  const [patientDetails, setPatientDetails] = useState({});
  const contentStyle = {
    display: isPdfMode ? "block" : "none",
  };
  const [observationTypesArray, setObservationTypesArray] = useState([]);
  const { selectedPatientId } = useSelector(patientSelector);
  const [encounterId, setEncounterId] = useState(null);
  const [consultationId, setConsultationId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFieldName, setModalFieldName] = useState("");
  const [modalFieldValue, setModalFieldValue] = useState("");
  const [currentField, setCurrentField] = useState("");
  const [htmlTemplateContent, setHtmlTemplateContent] = useState(null);
  const [errorMessage,setErrorMessage] = useState("");

  const styles = {
    mainContainer: {
      padding: "20px",
      maxWidth: "600px",
      margin: "auto",
    },
    header: {
      padding: "10px 20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    leftWrapper: {
      flex: "1",
    },
    title: {
      color: "#0099CC",
    },
    subTitle: {
      color: "#808080",
    },
    consultationSummary: {
      backgroundColor: "#F5FBFC",
      padding: "15px 20px",
      borderRadius: "5px",
      margin: "20px 0",
    },
    sectionTitle: {
      fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "#0099CC",
      color: "#ffffff",
      padding: "10px",
    },
    mainContentConatiner: {
      display: "flex",
      flexDirection: "row",
    },
    personalInfoWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    contentBlock: {
      margin: "10px 20px",
    },
    button: {
      display: "block",
      width: "100%",
      padding: "10px",
      margin: "20px 0",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  const onUnload = (event) => {
    localStorage.removeItem("reminders");
  };

  useEffect(() => {
    const preloadTemplate = async () => {
      try {
        const response = await dispatch(getTemplateAction("medical_letter")).unwrap();
        const htmlContent = response.result[0]?.html_content;
        setHtmlTemplateContent(htmlContent);
      } catch (error) {
        console.error("Error preloading HTML template:", error);
      }
    };
    preloadTemplate();
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
  }, []);

  useEffect(() => {
    if (selectedPatientId) {
      dispatch(getRecordTypeAction(selectedPatientId));
      fetchPatientProfile(selectedPatientId);
    }
  }, [selectedPatientId, dispatch]);

  useEffect(() => {
    setEncounterId(location.state?.id);
    setConsultationId(location.state?.consultationId);
  }, [location]);

  useEffect(() => {
    const isSummaryFilled =
      assessmentResponse?.differential_diagnosis?.trim().length > 0;
    const isNextStepsFilled = assessmentResponse?.next_steps?.trim().length > 0;

    setIsGenerateEnabled(isSummaryFilled && isNextStepsFilled);
  }, [
    assessmentResponse?.differential_diagnosis,
    assessmentResponse?.next_steps,
  ]);

  useEffect(() => {
    if (recordTypes && recordTypes?.length) {
      const excludeRecordTypes = [
        "blood_pressure",
        "blood_sugar",
        "pain_level",
        "temperature",
        "weight",
        "notes",
      ];

      const updatedRecordTypes = recordTypes
        .filter((record) => !excludeRecordTypes.includes(record.record_type))
        .map((record) => {
          return record;
        });
      setRecordTypesArray(updatedRecordTypes);
    }
  }, [recordTypes]);

  const { basic_details } = patientProfile || {};
  const healthHistory = patientProfile?.health_history;

  const populateHtmlContent = (ref, text) => {
    if (ref.current && htmlTemplateContent) {
      let modifiedHtmlContent = htmlTemplateContent;
      if (patientName) modifiedHtmlContent = modifiedHtmlContent.replace(/PATIENT NAME/g, capitalizeFirstLetter(patientName));
      if (patientAge) modifiedHtmlContent = modifiedHtmlContent.replace(/AGE/g, patientAge);
      if (gender) modifiedHtmlContent = modifiedHtmlContent.replace(/GENDER/g, capitalizeFirstLetter(gender));
      if (patientHeight) modifiedHtmlContent = modifiedHtmlContent.replace(/HEIGHT/g, patientHeight);
      if (patientWeight) modifiedHtmlContent = modifiedHtmlContent.replace(/WEIGHT/g, patientWeight);
      const formattedText = text.replace(/\n/g, "<br/>");
      ref.current.innerHTML = modifiedHtmlContent;  
      const paragraph = document.createElement("p");
      paragraph.innerHTML = formattedText;
      paragraph.style.margin = "20px";
      paragraph.style.fontSize = "16px";
      const prescriptionBody = ref.current.querySelector("#priscriptionBody");
      if (prescriptionBody) {
        prescriptionBody.appendChild(paragraph);
      } else {
        ref.current.appendChild(paragraph); 
      }
    }
  };
  
  const handleExpandField = (fieldName, fieldValue) => {
    setModalFieldName(fieldName);
    setModalFieldValue(fieldValue);
    setCurrentField(fieldName);
    setIsModalOpen(true);
  };

  const handleSaveField = (newText) => {
    setIsModalOpen(false);
    switch (currentField) {
      case "Patient History":
        setPatientHistory(newText);
        break;
      case "Symptoms":
        setSymptoms(newText);
        break;
      case "Physical Examination":
        setPhysicalExamination(newText);
        break;
      case "Other Notes":
        setOtherNotes(newText);
        break;
      case "Transcription":
        setTranscriptionResponse(newText);
        break;
      case "Consultation Summary":
        setDifferentialDiagnosis(newText);
        break;
      case "Recommended Next Steps":
        setNextSteps(newText);
        break;
      default:
        break;
    }
  };  

  const formatHealthHistory = (healthHistoryObj) => {
    const formattedEntries = Object.entries(healthHistoryObj)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => `${key}: ${value}`);
    if (formattedEntries?.length === 0) {
      return "";
    }
    return formattedEntries.join(", ");
  };

  const fetchObservationTypes = async () => {
    try {
      const response = await dispatch(getObservationTypesAction()).unwrap();
      const newObservationTypes = response?.result;
      setObservationTypesArray(newObservationTypes);
    } catch (error) {
      let errorMessage = "An error occurred while fetching the observation types.";

      if (error?.response) {
        // The request was made and the server responded with a non-2xx status code
        errorMessage = `Error: ${error.response.status} - ${
          error.response.data?.message || "Unable to fetch observation types."
        }`;
      } else if (error?.request) {
        // The request was made but no response was received
        errorMessage =
          "No response from the server. Please check your network connection.";
      } else if (error?.message) {
        // Other errors, such as invalid Axios config
        errorMessage = `Error: ${error.message}`;
      } else {
        // Fallback for unexpected issues
        errorMessage = "An unknown error occurred.";
      }
  
      console.error("Fetch observation types Error:", errorMessage);
  
      // Optionally set error to state or alert the user
      if (setErrorMessage) {
        setErrorMessage(errorMessage); // Ensure setErrorMessage is defined
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchObservationTypes();
  }, []);

  useEffect(() => {
    dispatch(resetState());
    dispatch(resetAiSummaries());
  }, [location, dispatch]);

  useEffect(() => {
    if (basic_details) {
      setPatientAge(calculateAge(basic_details?.dob || ""));
      setGender(basic_details?.gender || "");
      setPatientName(
        `${basic_details.first_name || ""} ${
          basic_details.last_name || ""
        }`.trim()
      );
    }
  }, [basic_details]);

  useEffect(() => {
    if (healthHistory) {
      setPatientHistory(formatHealthHistory(healthHistory));
    }
  }, [healthHistory]);

  useEffect(() => {
    setDifferentialDiagnosis(assessmentResponse?.differential_diagnosis || "");
    setNextSteps(assessmentResponse?.next_steps || "");
  }, [assessmentResponse]);

  useEffect(() => {
    const isSummaryFilled = differentialDiagnosis?.trim().length > 0;
    const isNextStepsFilled = nextSteps?.trim().length > 0;

    setIsGenerateEnabled(isSummaryFilled && isNextStepsFilled);
  }, [differentialDiagnosis, nextSteps]);

  useEffect(() => {
    if (documentData && shouldOpenDocument) {
      const blob = new Blob([documentData], { type: "application/pdf" });
      setPdfBlob(blob);

      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");

      setShouldOpenDocument(false);
    }
  }, [documentData, shouldOpenDocument]);

  const fetchPatientProfile = async (id) => {
    try {
      // Ensure getPatientProfileAction is awaited properly
      await dispatch(getPatientProfileAction(id));
    } catch (error) {
      let errorMessage = "An error occurred while fetching the patient profile.";
  
      if (error?.response) {
        // The request was made and the server responded with a non-2xx status code
        errorMessage = `Error: ${error.response.status} - ${
          error.response.data?.message || "Unable to fetch profile."
        }`;
      } else if (error?.request) {
        // The request was made but no response was received
        errorMessage =
          "No response from the server. Please check your network connection.";
      } else if (error?.message) {
        // Other errors, such as invalid Axios config
        errorMessage = `Error: ${error.message}`;
      } else {
        // Fallback for unexpected issues
        errorMessage = "An unknown error occurred.";
      }
  
      console.error("Fetch Patient Profile Error:", errorMessage);
  
      // Optionally set error to state or alert the user
      if (setErrorMessage) {
        setErrorMessage(errorMessage); // Ensure setErrorMessage is defined
      }
    }
  };
  

  useEffect(() => {
    getLocalReminders();
  }, []);

  const getLocalReminders = () => {
    let existingReminders = localStorage.getItem("reminders")
      ? JSON.parse(localStorage.getItem("reminders"))
      : [];
    setLocalReminders(existingReminders);
  };

  const onAddRecords = async (newSelectedRecords) => {
    setShowAddRecordsModal(false);
    const filesToFetch = newSelectedRecords.filter(
      (newRecord) =>
        !selectedFiles.some((existingFile) => existingFile.id === newRecord.id)
    );

    setSelectedFiles((prev) => [...prev, ...filesToFetch]);
    setIsSummaryLoading(true);

    const promises = filesToFetch.map((record) => {
      return dispatch(
        getSingleRecordAction({
          patientId: selectedPatientId,
          recordType: record?.record_type,
          recordId: record?.id,
        })
      );
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Error fetching selected records:", error);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const onUploadAudio = () => {
    setshowAudioRecordModal(false);
  };

  const callHemebotApi = async (
    query,
    currentChatHistory,
    initializeChat = false
  ) => {
    const newUserMessageEntry = {
      role: "user",
      content: query,
    };

    const updatedChatHistory = initializeChat
      ? [newUserMessageEntry]
      : [...currentChatHistory, newUserMessageEntry];
    let payload = null;
    if (initializeChat) {
      payload = {
        chats: updatedChatHistory,
      };
    } else {
      payload = {
        chats: updatedChatHistory,
        system_prompt: systemPrompt,
      };
    }
    try {
      const actionResult = await dispatch(callHemeBotAction(payload));
      const response = actionResult.payload?.data;
      if (response?.chatbot?.content && response?.system_prompt) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            role: "assistant",
            content: response.chatbot.content,
          },
        ]);
        setSystemPrompt(response.system_prompt);
      }
      setUserSentMessage(true);
    } catch (error) {
      console.error("Error calling HemeBot:", error);
    }
  };

  useEffect(() => {
    setSingleRecordResponse([]);
  }, []);

  const handleNewUserMessage = useCallback(
    (newMessage) => {
      callHemebotApi(newMessage, chatHistory);
    },
    [chatHistory]
  );

  const openPdfInNewTab = (file) => {
    const blobUrl = URL.createObjectURL(file);
    window.open(blobUrl, "_blank");
  };

  const postTranscriptCallback = ({infoResponse, transcript}) => {
    if (infoResponse?.status === "success") {
      setPatientDetails(infoResponse?.result);
      const { patient_info, reminders, summary } = infoResponse?.result;
      const allReminders = [...localreminders, ...reminders];
      setLocalReminders(allReminders);
      if (transcript?.length) {
        setTranscriptionResponse(
          transcript || transcriptionResponse || ""
        );
      }
      if (patient_info?.patient_diagnosis?.other_notes?.length) {
        setOtherNotes(
          patient_info?.patient_diagnosis?.other_notes || otherNotes || ""
        );
      }
      if (patient_info?.patient_diagnosis?.patient_history?.length) {
        setPatientHistory(
          patient_info?.patient_diagnosis?.patient_history ||
            patientHistory ||
            ""
        );
      }
      if (patient_info?.patient_diagnosis?.physical_examination?.length) {
        setPhysicalExamination(
          patient_info?.patient_diagnosis?.physical_examination ||
            physicalExamination ||
            ""
        );
      }
      if (patient_info?.patient_diagnosis?.symptoms?.length) {
        setSymptoms(
          patient_info?.patient_diagnosis?.symptoms || symptoms || ""
        );
      }
      if (patient_info?.personal_information?.age?.length) {
        setPatientAge(
          patient_info?.personal_information?.age || patientAge || ""
        );
      }
      if (patient_info?.personal_information?.gender?.length) {
        setGender(patient_info?.personal_information?.gender || gender || "");
      }
      if (patient_info?.personal_information?.height?.length) {
        setPatientHeight(
          patient_info?.personal_information?.height || patientHeight || ""
        );
      }
      if (patient_info?.personal_information?.weight?.length) {
        setPatientWeight(
          patient_info?.personal_information?.weight || patientWeight || ""
        );
      }
      if (patient_info?.recommended_next_steps?.tests_recommended?.length) {
        setNextSteps(
          patient_info?.recommended_next_steps?.tests_recommended ||
            nextSteps ||
            ""
        );
      }
      if (summary?.length) {
        setDifferentialDiagnosis(summary || differentialDiagnosis || "");
      }
      if (patient_info?.vitals?.blood_pressure?.length) {
        setBloodPressure(
          patient_info?.vitals?.blood_pressure || bloodPressure || ""
        );
      }
      if (patient_info?.vitals?.heart_rate?.length) {
        setHeartRate(patient_info?.vitals?.heart_rate || heartRate || "");
      }
      if (patient_info?.vitals?.random_blood_sugar?.length) {
        setRandomBloodSugar(
          patient_info?.vitals?.random_blood_sugar || randomBloodSugar || ""
        );
      }
      if (patient_info?.vitals?.respiratory_rate?.length) {
        setRespiratoryRate(
          patient_info?.vitals?.respiratory_rate || respiratoryRate || ""
        );
      }
      if (patient_info?.vitals?.spo2?.length) {
        setSpo2(patient_info?.vitals?.spo2 || spo2 || "");
      }
      if (patient_info?.vitals?.temperature?.length) {
        setTemperature(patient_info?.vitals?.temperature || temperature || "");
      }
    }
  };

  const generatePDF = async (ref, fileName, text) => {
    populateHtmlContent(ref, text);
    const options = {
      margin: [10, 0],
      filename: fileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4" },
    };
    return await html2pdf().from(ref.current).set(options).outputPdf("blob");
  };
  
  const handleGenerateDocuments = async () => {
    setIsGenerateLoading(true);
    try {
      const summaryBlob = await generatePDF(summaryRef, "summary.pdf", differentialDiagnosis);
      const transcriptionBlob = await generatePDF(transcriptionRef, "transcription.pdf", transcriptionResponse);
      const prescriptionBlob = await generatePDF(prescriptionRef, "prescription.pdf", nextSteps);

      [summaryBlob, transcriptionBlob, prescriptionBlob].forEach((blob, idx) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        } else {
          console.warn(`Document generation for ${["summary", "transcription", "prescription"][idx]} failed.`);
        }
      });
  
      const currentDate = moment().format();
      let temperaturePayload = null;
      if (temperature) {
        temperaturePayload = {
          observation_data: {
            observation: {
              observation_type: "8310-5",
              components: [{ code: "8310-5", value: extractNumericValue(temperature) }],
              date: currentDate,
              encounter_id: encounterId,
            },
          },
          patient_id: selectedPatientId,
        };
      }
  
      let bloodPressurePayload = null;
      if (bloodPressure || heartRate) {
        const [systolic, diastolic] = formatBloodPressure(bloodPressure).split("/").map(Number);
        bloodPressurePayload = {
          observation_data: {
            observation: {
              observation_type: "8478-0",
              components: [
                { code: "8480-6", value: systolic || 0 },
                { code: "8462-4", value: diastolic || 0},
                { code: "8478-0", value: extractNumericValue(heartRate) || 0 }
              ],
              date: currentDate,
              encounter_id: encounterId,
            },
          },
          patient_id: selectedPatientId,
        };
      }
  
      let weightPayload = null;
      if (patientWeight) {
        weightPayload = {
          observation_data: {
            observation: {
              observation_type: "29463-7",
              components: [{ code: "29463-7", value: extractNumericValue(patientWeight) }],
              date: currentDate,
              encounter_id: encounterId,
            },
          },
          patient_id: selectedPatientId,
        };
      }
  
      // Send observations if payloads are available
      if (temperaturePayload) {
        try {
          await dispatch(addObservationAction(temperaturePayload));
        } catch (error) {
          console.error("Error adding temperature observation:", error);
        }
      }
  
      if (bloodPressurePayload) {
        try {
          await dispatch(addObservationAction(bloodPressurePayload));
        } catch (error) {
          console.error("Error adding blood pressure observation:", error);
        }
      }
  
      if (weightPayload) {
        try {
          await dispatch(addObservationAction(weightPayload));
        } catch (error) {
          console.error("Error adding weight observation:", error);
        }
      }
  
      navigate("add-records", {
        state: {
          generatedFiles: [
            { blob: summaryBlob, name: "summary.pdf" },
            { blob: transcriptionBlob, name: "transcription.pdf" },
            { blob: prescriptionBlob, name: "prescription.pdf" },
          ],
          encounterID : encounterId,
          consultationID: consultationId
        },
      });
    } catch (error) {
      console.error("Error generating documents:", error);
    } finally {
      setIsGenerateLoading(false);
    }
  };     

  const onAudioRecord = async () => {
    setshowAudioRecordModal(true)
    // navigate('/deepgram')
  };

  const onSendRecords = () => {};

  const renderLeftSide = () => (
    <Styled.LeftSide>
      <Styled.MainInfoContainer>
        <Styled.PersonalInfoContainer>
          <Styled.ListSection>
            <Styled.ListDateTitle>
              <Styled.SideIcon src={IPersonalInfo}></Styled.SideIcon>
              <Styled.HeadingText>Personal Information</Styled.HeadingText>
            </Styled.ListDateTitle>
          </Styled.ListSection>
          <Styled.InputWrapper>
            <Styled.LabelAndInput>
              <Styled.Label>Patient Age</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.Input
                  placeholder="Enter age"
                  value={patientAge}
                  onChange={(e) => setPatientAge(e.target.value)}
                />
                <Styled.UnitLabel>years</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInput>
            <Styled.LabelAndInput>
              <Styled.Label>Gender</Styled.Label>
              <Styled.Dropdown
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Styled.Dropdown>
            </Styled.LabelAndInput>
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Styled.LabelAndInput>
              <Styled.Label>Height</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.Input
                  value={patientHeight}
                  onChange={(e) => setPatientHeight(e.target.value)}
                />
                {/* <Styled.UnitLabel>cms</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInput>

            <Styled.LabelAndInput>
              <Styled.Label>Weight</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.Input
                  value={patientWeight}
                  onChange={(e) => setPatientWeight(e.target.value)}
                />
                {/* <Styled.UnitLabel>kgs</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInput>
          </Styled.InputWrapper>
        </Styled.PersonalInfoContainer>
        <Styled.PersonalInfoContainer>
          <Styled.ListSection>
            <Styled.ListDateTitle>
              <Styled.SideIcon src={IVitals}></Styled.SideIcon>
              <Styled.HeadingText>Vitals</Styled.HeadingText>
            </Styled.ListDateTitle>
          </Styled.ListSection>
          <Styled.InputWrapper>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Blood Pressure</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={bloodPressure}
                  onChange={(e) => setBloodPressure(e.target.value)}
                />
                {/* <Styled.UnitLabel>mm Hg</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Heart Rate</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={heartRate}
                  onChange={(e) => setHeartRate(e.target.value)}
                />
                {/* <Styled.UnitLabel>/ min</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>SPO2</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={spo2}
                  onChange={(e) => setSpo2(e.target.value)}
                />
                {/* <Styled.UnitLabel>%</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Respiratory Rate</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={respiratoryRate}
                  onChange={(e) => setRespiratoryRate(e.target.value)}
                />
                {/* <Styled.UnitLabel>/ min</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Temperature</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={temperature}
                  onChange={(e) => setTemperature(e.target.value)}
                />
                {/* <Styled.UnitLabel>F</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Random Blood Sugar</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={randomBloodSugar}
                  onChange={(e) => setRandomBloodSugar(e.target.value)}
                />
                {/* <Styled.UnitLabel>mg/dL</Styled.UnitLabel> */}
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
          </Styled.InputWrapper>
        </Styled.PersonalInfoContainer>
      </Styled.MainInfoContainer>

      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={IDiagnosis}></Styled.SideIcon>
            <Styled.HeadingText>Patient Diagnosis</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Patient History</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter patient history..."
                value={patientHistory}
                onChange={(e) => setPatientHistory(e.target.value)}
              />
              <Styled.ButtonContainer>
                <Styled.ExpandButton
                  src={IExpandButton}
                  onClick={() => handleExpandField("Patient History", patientHistory)}
                />
                <Styled.CopyButton
                  src={ICopyButton}
                  onClick={() => copyToClipboard(patientHistory, dispatch)}
                />
              </Styled.ButtonContainer>
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Symptoms</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter symptoms..."
                value={symptoms}
                onChange={(e) => setSymptoms(e.target.value)}
              />
              <Styled.ButtonContainer>
                <Styled.ExpandButton
                  src={IExpandButton}
                  onClick={() => handleExpandField("Symptoms", symptoms)}
                />
                <Styled.CopyButton
                  src={ICopyButton}
                  onClick={() => copyToClipboard(symptoms, dispatch)}
                />
              </Styled.ButtonContainer>
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Physical Examination</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter physical examination details..."
                value={physicalExamination}
                onChange={(e) => setPhysicalExamination(e.target.value)}
              />
              <Styled.ButtonContainer>
                <Styled.ExpandButton
                  src={IExpandButton}
                  onClick={() => handleExpandField("Physical Examination", physicalExamination)}
                />
                <Styled.CopyButton
                  src={ICopyButton}
                  onClick={() => copyToClipboard(physicalExamination, dispatch)}
                />
              </Styled.ButtonContainer>
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Other Notes</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter other notes..."
                value={otherNotes}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
              <Styled.ButtonContainer>
                <Styled.ExpandButton
                  src={IExpandButton}
                  onClick={() => handleExpandField("Other Notes", otherNotes)}
                />
                <Styled.CopyButton
                  src={ICopyButton}
                  onClick={() => copyToClipboard(otherNotes, dispatch)}
                />
              </Styled.ButtonContainer>
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={ITranscription}></Styled.SideIcon>
            <Styled.HeadingText>Transcription</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.TextAreaContainer>
            <Styled.TranscriptionextArea
              name = "transcriptionResponse"
              value={transcriptionResponse || ""}
              onChange={(e) => setTranscriptionResponse(e.target.value)}
            />
            <Styled.ExpandButtonTextArea
              src={IExpandButton}
              onClick={() => handleExpandField("Transcription", transcriptionResponse)}
            />
            <Styled.CopyButtonTextArea
              src={ICopyButton}
              onClick={() => copyToClipboard(transcriptionResponse, dispatch)}
            />
          </Styled.TextAreaContainer>
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
    </Styled.LeftSide>
  );

  return (
    <Styled.Container>
      {errorMessage && <div>{errorMessage}</div>}
      <Styled.HeaderWrapper>
        <Styled.TopHeader>
          <Styled.ArrowBackImage
            src={IBackArrow}
            alt="back-arrow"
            onClick={() => {
              navigate(-1);
            }}
          />
          New Encounter
        </Styled.TopHeader>
        <Styled.AudioButtonWrapper>
          <Styled.RecordButton onClick={onAudioRecord}>
            {isTranscriptionProgress === "inProgress" ? (
              <Loader color="inherit" />
            ) : (
              <>
                <Styled.SideIcon src={IMicIcon} alt="Mic Icon" />
                Audio Scribe
              </>
            )}
          </Styled.RecordButton>
        </Styled.AudioButtonWrapper>
      </Styled.HeaderWrapper>
      <Styled.MainContent>{renderLeftSide()}</Styled.MainContent>
      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={IConsultation}></Styled.SideIcon>
            <Styled.HeadingText>Consultation Summary</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.TextAreaContainer>
            <Styled.TranscriptionextArea
              name="differentialDiagnosis"
              defaultValue={differentialDiagnosis || ""}
              onChange={(e) => setDifferentialDiagnosis(e.target.value)}
            />
            <Styled.ExpandButtonTextArea
              src={IExpandButton}
              onClick={() => handleExpandField("Consultation Summary", differentialDiagnosis)}
            />
            <Styled.CopyButtonTextArea
              src={ICopyButton}
              onClick={() => copyToClipboard(differentialDiagnosis, dispatch)}
            />
          </Styled.TextAreaContainer>
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={INextSteps}></Styled.SideIcon>
            <Styled.HeadingText>Recommended Next Steps</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.TextAreaContainer>
            <Styled.TranscriptionextArea
              name="nextSteps"
              defaultValue={nextSteps || ""}
              onChange={(e) => setNextSteps(e.target.value)}
            />
            <Styled.ExpandButtonTextArea
              src={IExpandButton}
              onClick={() => handleExpandField("Recommended Next Steps", nextSteps)}
            />
            <Styled.CopyButtonTextArea
              src={ICopyButton}
              onClick={() => copyToClipboard(nextSteps, dispatch)}
            />
          </Styled.TextAreaContainer>
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
      {/* <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.HeadTitleSection>
              <Styled.ListDateTitle>
                <Styled.SideIcon src={IRemindersIcon}></Styled.SideIcon>
                <Styled.HeadingText>Reminders</Styled.HeadingText>
              </Styled.ListDateTitle>
              <div>
                <Styled.SubmitButton
                  onClick={() => {
                    navigate("consultation/add-reminder");
                  }}
                  type="submit"
                >
                  Add Reminder
                </Styled.SubmitButton>
              </div>
            </Styled.HeadTitleSection>
          </Styled.ListDateTitle>
        </Styled.ListSection>

        <Styled.ReminderCardBox>
          <Styled.ButtonBox></Styled.ButtonBox> */}
          {/* {patientDetails?.reminders?.length > 0 &&
            patientDetails?.reminders.map((reminder, index) => (
              <Styled.ReminderCard>
                <CustomReminderCard
                  reminder={reminder}
                  icon={observationTypesArray?.find((type) => {
                    return type.record_type == reminder.reminder_type?.type;
                  })}
                />
              </Styled.ReminderCard>
            ))} */}

          {/* {localreminders?.length > 0 &&
            localreminders.map((reminder, index) => (
              <Styled.ReminderCard>
                <CustomReminderCard
                getLocalReminders={getLocalReminders}
                  index={index}
                  reminder={reminder}
                  icon={observationTypesArray?.find((type) => {
                    return type.record_type == reminder.reminder_type?.type;
                  })}
                />
              </Styled.ReminderCard>
            ))} */}

          {/* {patientDetails?.reminders?.length == 0 && */}
          {/* {localreminders?.length == 0 && (
            <EmptyListMessage>No reminders found</EmptyListMessage>
          )}
        </Styled.ReminderCardBox>
      </Styled.PersonalInfoContainer> */}
      <Styled.ButtonWrapper>
        <Styled.GenerateButton
          onClick={handleGenerateDocuments}
          disabled={!isGenerateEnabled}
        >
          {isGenerateLoading ? <Loader /> : 'Generate and Add Records'}
        </Styled.GenerateButton>
      </Styled.ButtonWrapper>
      <AttachRecordsModal
        open={showAddRecordsModal}
        handleClose={() => setShowAddRecordsModal(false)}
        handleSave={onAddRecords}
        alreadySelectedFiles={selectedFiles}
        recordTypesArray={recordTypesArray}
      />
      <TextExpandModal
        open={isModalOpen}
        fieldName={modalFieldName}
        value={modalFieldValue}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveField}
      />
      {showAudioRecordModal && (
        <AudioRecordModal
          open={showAudioRecordModal}
          handleClose={() => setshowAudioRecordModal(false)}
          handleSave={onUploadAudio}
          setPhysicalExamination={setPhysicalExamination}
          setOtherNotes={setOtherNotes}
          setPatientHistory={setPatientHistory}
          setSymptoms={setSymptoms}
          physicalExamination={physicalExamination}
          otherNotes={otherNotes}
          patientHistory={patientHistory}
          symptoms={symptoms}
          setTranscriptionResponse={setTranscriptionResponse}
          setIsTranscriptionProgress={setIsTranscriptionProgress}
          isTranscriptionProgress={isTranscriptionProgress}
          postTranscriptCallback={postTranscriptCallback}
        />
      )}
      <AddRecords
        open={showSendRecordsModal}
        handleClose={() => setShowSendRecordsModal(false)}
        handleSave={onSendRecords}
        pdfFiles={pdfFiles}
        encounterId = {encounterId}
      />
      <SnackBar />
      <ChatWidgetComponent
        handleNewUserMessage={handleNewUserMessage}
        userSentMessage={userSentMessage}
        assessmentRun={assessmentRun}
      />
      <div style={{display: 'none'}}>
        <div ref={summaryRef} ></div>
        <div ref={transcriptionRef} ></div>
        <div ref={prescriptionRef} ></div>
      </div>
    </Styled.Container>
  );
};
export default NewEncounter;
