import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { Box } from "@mui/material";
import "./graphicalRecord.css";

const rangeOptions = [
  { label: "1 Week", value: "weekly" },
  { label: "6 Months", value: "six_months" },
  { label: "1 Year", value: "yearly" },
  { label: "All", value: "all" },
  { label: "Custom", value: "custom" },
];

const GraphicalRecord = ({ graphData }) => {
  const [range, setRange] = useState("all");
  const [customRange, setCustomRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filterData = () => {
    const now = new Date();
    let startDate, endDate;
  
    if (range === "weekly") {
      startDate = new Date();
      startDate.setDate(now.getDate() - 7);
      endDate = now;
    } else if (range === "six_months") {
      startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      endDate = now;
    } else if (range === "yearly") {
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      endDate = now;
    } else if (range === "custom") {
      if (customRange.startDate && customRange.endDate) {
        startDate = new Date(customRange.startDate);
        endDate = new Date(customRange.endDate);
        startDate.setHours(0, 0, 0, 0); // Normalize to start of the day
        endDate.setHours(23, 59, 59, 999); // Normalize to end of the day
      } else {
        return []; 
      }
    }
  
    if (range === "all") return graphData;
  
    return graphData.map((dataset) => ({
      ...dataset,
      data: dataset.data.filter((point) => {
        const pointDate = new Date(point.x);
        return pointDate >= startDate && pointDate <= endDate;
      }),
    }));
  };
  

  const filteredGraphData = filterData();
  const isNoData = filteredGraphData.every(
    (dataset) => !dataset.data || dataset.data.length === 0
  );

  const seriesData = filteredGraphData.map((dataset) => ({
    name: dataset.label,
    type: "line",
    smooth: true,
    data: dataset.data.map((point) => [point.x, point.y]),
    lineStyle: { color: dataset.borderColor, width: 2 },
    itemStyle: { color: dataset.borderColor },
    symbol: "circle",
    symbolSize: 8,
    showSymbol: true,
  }));

  const chartOptions = {
    tooltip: { trigger: "axis" },
    legend: {
      data: graphData.map((dataset) => dataset.label),
      top: "5%",
    },
    xAxis: {
      type: "time",
      nameLocation: "center",
      nameGap: 40,
      axisLabel: {
        formatter: (value) => {
          const date = new Date(value);
          if (range === "weekly") {
            return date.toLocaleString("default", { weekday: "short" });
          }
          return date.toLocaleString("default", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
        },
        rotate: 45,
        align: "right",
        interval: 0,
        show: !isMobile,
      },
      splitLine: { show: false },
      axisTick: { alignWithLabel: true },
    },
    yAxis: {
      type: "value",
      nameLocation: "center",
    },
    series: seriesData,
    grid: {
      left: "10%",
      right: "5%",
      bottom: "10%",
      containLabel: true,
    },
  };

  return (
    <div className="graphical-record-container">
      <Box
        sx={{
          display: isMobile ? "block" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 1,
        }}
      >
        <div className="filter-buttons">
          {rangeOptions.map(({ label, value }) => (
            <button
              key={value}
              className={`filter-button ${range === value ? "active" : ""}`}
              onClick={() => setRange(value)}
            >
              {label}
            </button>
          ))}
        </div>
        {range === "custom" && (
          <div className="custom-range">
            <input
              type="date"
              value={customRange.startDate || ""}
              onChange={(e) =>
                setCustomRange({ ...customRange, startDate: e.target.value })
              }
              placeholder="Start Date"
              max={new Date().toISOString().split("T")[0]}
            />
            <input
              type="date"
              value={customRange.endDate || ""}
              onChange={(e) =>
                setCustomRange({ ...customRange, endDate: e.target.value })
              }
              placeholder="End Date"
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        )}
      </Box>
      {isNoData ? (
        <div className="no-record-message">No record found</div>
      ) : (
        <div className="chart-container">
          <ReactECharts
            option={chartOptions}
            style={{ height: isMobile ? "350px" : "450px" }}
          />
        </div>
      )}
    </div>
  );
};

export default GraphicalRecord;
