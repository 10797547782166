import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberValidation.css';

const PhoneNumberValidation = ({ onPhoneNumberChange, selectedCountry }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = (value) => {
    setPhoneNumber(value);
    if (onPhoneNumberChange) {
      onPhoneNumberChange(value);
    }
  };

  return (
    <div>
        <PhoneInput
          country={selectedCountry && selectedCountry?.code?.toLowerCase() || 'in'}
          value={phoneNumber}
          onChange={handleChange}
          inputProps={{
            required: true,
          }}
        />
    </div>
  );
};

export default PhoneNumberValidation;