import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Button,
  TablePagination,
  Box,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./TeleRadiology.css"; // For custom styles
import axios from "axios";
import { redirect } from "react-router-dom";
import { Loader } from "../../components/shared";
import {
  fetchTeleRadiologyData,
  getTeleRadiologyData,
} from "../../api/tele-radiology.api";
import StatusDropdown from "../../components/shared/StatusDropdown";
import Breadcrumb from "../../components/layout/Breadcrumb";
import StatusDropDownStatus from "../../components/shared/StatusDropDownStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  patientSelector,
  setSelectedPatient,
} from "../../store/slice/patient.slice";
import ConfirmationBox from "../../components/InfoSection/ConfirmationBox";
import { ClearSearch, ISearch, SortDownArrow, SortUpArrow } from "../../assets";
import { AddIcon, EmptyListMessage } from "../patients-list/style.components";
import { ShimmerTable } from "react-shimmer-effects";

const TeleRadiology = () => {
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = React.useState("time_passed_since_creation");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterByName, setFilterByName] = useState("");
  const [filterByNumber, setFilterByNumber] = useState("");
  const [filterByEmail, setFilterByEmail] = useState("");
  const [filterByLab, setFilterByLab] = useState("");
  const [filterByUrgency, setFilterByUrgency] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByDate, setFilterByDate] = useState(null);
  const [fileterByComments, setFilterByComments] = useState("");
  const [comment, setComment] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const { selectedPatientId } = useSelector(patientSelector);
  const [isShowChangeStatus, setShowChangeStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const formatTimeAgo = (timeString) => {
    if (!timeString) return "-";

    // Split the string by commas and spaces to extract days, hours, and minutes
    const parts = timeString.split(/, | /);

    // Extract specific time components
    const days = parts.includes("days")
      ? parts[parts.indexOf("days") - 1] + "D"
      : "0D";
    const hours = parts.includes("hours")
      ? parts[parts.indexOf("hours") - 1] + "H"
      : "0H";
    const minutes = parts.includes("minutes")
      ? parts[parts.indexOf("minutes") - 1] + "M"
      : "0M";

    // Return formatted string
    return `${days}, ${hours}, ${minutes}`;
  };

  const dropdownOptions = [
    { label: "Pending", value: "draft" },
    { label: "Completed", value: "completed" },
    { label: "Rejected", value: "revoked" },
  ];

  // useEffect(() => {
  //   dispatch(setSelectedPatient({ id: selectedPatientId }));
  // }, [])

  console.log("selectedPatientId", selectedPatientId);
  const getStatusStyles = (status) => {
    const styles = {
      draft: {
        sx: {
          backgroundColor: "#CC8552",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CC8552",
          },
        },
      },
      completed: {
        sx: {
          backgroundColor: "#7ABA56",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7ABA56",
          },
        },
      },
      revoked: {
        sx: {
          backgroundColor: "#808080",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#808080",
          },
        },
      },
      default: {
        sx: {
          backgroundColor: "#FFF",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CCC",
          },
        },
      },
    };
    return styles[status] || styles.default;
  };
  const navigate = useNavigate();

  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate("/");
  };

  const breadCrubLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: "Tele-Radiology",
    },
  ];

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const response = await fetchTeleRadiologyData(page);
        setData(response.result); // Update the state with the data
        setLoading(false);
        console.log("Fetched data:", response.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    return `${day}-${month}-${year}`; // Format: YYYY-MM-DD
  };

  const handleColumnFilter = (column, value) => {
    if (column === "name") setFilterByName(value);
    if (column === "number") setFilterByNumber(value);
    if (column === "email") setFilterByEmail(value);
    if (column === "lab") setFilterByLab(value);
    if (column === "urgency") setFilterByUrgency(value);
    if (column === "status") setFilterByStatus(value);
    if (column === "date") setFilterByDate(value);
    if (column === "comments") setFilterByComments(value);
  };

  const includesFilter = (fieldValue, filterValue) => {
    if (!filterValue) return true; // If filterValue is empty, include the row
    return (fieldValue || "")
      .toString()
      .toLowerCase()
      .includes(filterValue.toString().toLowerCase());
  };

  const filteredData = [...data]
    .filter((row) => {
      const requestDate = formatDate(row.request_date); // Convert to YYYY-MM-DD
      const filterDate = filterByDate ? formatDate(filterByDate) : "";
      return (
        includesFilter(row.patient_name, filterByName) &&
        includesFilter(row.phone_number, filterByNumber) &&
        includesFilter(row.email_address, filterByEmail) &&
        includesFilter(row.requester_name, filterByLab) &&
        includesFilter(row.urgency, filterByUrgency) &&
        includesFilter(row.status, filterByStatus) &&
        (!filterByStatus || row.status === filterByStatus) &&
        (!filterByDate || requestDate === filterDate) && // Compare YYYY-MM-DD format
        includesFilter(row.comments, fileterByComments)
      );
    })
    .sort((a, b) => {
      // Sorting logic
      const valueA = a[orderBy]?.toString().toLowerCase() || ""; // Ensure null-safe
      const valueB = b[orderBy]?.toString().toLowerCase() || "";

      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });

  const postTeleRadiologyData = async (request_id, updatedData) => {
    setLoading(true);
    await getTeleRadiologyData(request_id, updatedData);
    try {
      const response = await fetchTeleRadiologyData();
      setLoading(false);
      setData(response.result); // Update the state with the data
      console.log("Fetched data:", response.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return;
  };

  const handleSort = (field) => {
    console.log(field);
    if (sortBy === field) {
      setOrder(order === "asc" ? "desc" : "asc"); // Toggle direction
    } else {
      setSortBy(field);
      setOrder("asc"); // Default to ascending for new field
    }
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleStatus = (request_id, status) => {
    setShowChangeStatus(true);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    console.log("New Rows Per Page:", newRowsPerPage);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  // Calculate paginated data
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleScanClick = (
    fhir_id,
    request_id,
    requester_name,
    comments,
    request_date,
    status,
    urgency,
    patient_id
  ) => {
    dispatch(setSelectedPatient({ id: patient_id }));
    navigate("scan-info", {
      state: {
        studyFhirId: fhir_id,
        request_id: request_id,
        performer_info: requester_name,
        comments: comments,
        request_date: request_date,
        status: status,
        urgency: urgency,
      },
    });
  };

  const clearSearchHandler = () => {
    setFilterByName("");
    setFilterByNumber("");
    setFilterByEmail("");
    setFilterByLab("");
    setFilterByUrgency("");
    setFilterByStatus("");
    setFilterByDate("");
    setFilterByComments("");
  };

  const convertToDays = (timeString) => {
    if (!timeString) return 0;

    const parts = timeString.split(/, | /);
    const days = parts.includes("days")
      ? parseInt(parts[parts.indexOf("days") - 1])
      : 0;
    const hours = parts.includes("hours")
      ? parseInt(parts[parts.indexOf("hours") - 1])
      : 0;
    const minutes = parts.includes("minutes")
      ? parseInt(parts[parts.indexOf("minutes") - 1])
      : 0;

    // Return the total time in days
    return days + hours / 24 + minutes / 1440; // Hours are converted to days, minutes to fraction of a day
  };

  const sortedData = React.useMemo(() => {
    return [...paginatedData].sort((a, b) => {
      let aValue = a[sortBy];
      let bValue = b[sortBy];

      // Handle null or undefined values
      if (aValue == null) aValue = "";
      if (bValue == null) bValue = "";

      // Convert specific fields for comparison
      if (
        sortBy === "time_passed_since_creation" ||
        sortBy === "requested_date"
      ) {
        aValue = convertToDays(aValue);
        bValue = convertToDays(bValue);
      } else if (sortBy === "request_date") {
        aValue = new Date(aValue).getTime(); // Convert to timestamp for accurate comparison
        bValue = new Date(bValue).getTime();
      }

      // Sort based on the order
      if (order === "asc") {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    });
  }, [paginatedData, sortBy, order]); // Dependencies for re-sorting

  return (
    <div>
      <div
        style={{
          margin: 30,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Breadcrumb on the left */}
        <Breadcrumb links={breadCrubLinks} />

        {/* Clear Search button on the right */}
        <Button
          style={{
            backgroundColor: "#079ACE",
            color: "#FFF",
            border: "none",
            borderRadius: "10px",
            padding: "8px 16px",
            cursor: "pointer",
            display: "flex", // Ensure the button content aligns properly
            alignItems: "center",
            gap: "8px", // Adds spacing between the icon and text
          }}
          onClick={clearSearchHandler}
        >
          <AddIcon
            src={ClearSearch}
            style={{ width: "16px", height: "16px" }}
          />
          <span>Clear Search</span>
        </Button>
      </div>

      <Paper style={{ margin: 30, fontFamily: "NotoSans" }}>
        {/* Data Table */}
        <TableContainer>
          {loading ? (
            <ShimmerTable row={5} />
          ) : (
            <Table>
              <TableHead>
                {/* Headings Row */}
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", width: "12%" }}>
                    {" "}
                    <TableSortLabel
                      active={sortBy === "patient_name"}
                      direction={sortBy === "patient_name" ? order : "asc"}
                      onClick={() => handleSort("patient_name")}
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      IconComponent={() => (
                        <span style={{ display: "inline-flex", marginLeft: 4 }}>
                          {sortBy === "patient_name" ? (
                            order === "asc" ? (
                              <AddIcon
                                src={SortUpArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <AddIcon
                                src={SortDownArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            )
                          ) : (
                            <AddIcon
                              src={SortDownArrow}
                              style={{ width: "16px", height: "16px" }}
                            />
                          )}
                        </span>
                      )}
                    >
                      Patient Name
                    </TableSortLabel>
                  </TableCell>

                  <TableCell style={{ fontWeight: "bold", width: "12%" }}>
                    {" "}
                    <TableSortLabel
                      active={sortBy === "requester_name"}
                      direction={sortBy === "requester_name" ? order : "asc"}
                      onClick={() => handleSort("requester_name")}
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      IconComponent={() => (
                        <span style={{ display: "inline-flex", marginLeft: 4 }}>
                          {sortBy === "requester_name" ? (
                            order === "asc" ? (
                              <AddIcon
                                src={SortUpArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <AddIcon
                                src={SortDownArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            )
                          ) : (
                            <AddIcon
                              src={SortDownArrow}
                              style={{ width: "16px", height: "16px" }}
                            />
                          )}
                        </span>
                      )}
                    >
                      Lab Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", width: "10%" }}>
                    <TableSortLabel
                      active={sortBy === "urgency"}
                      direction={sortBy === "urgency" ? order : "asc"}
                      onClick={() => handleSort("urgency")}
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      IconComponent={() => (
                        <span style={{ display: "inline-flex", marginLeft: 4 }}>
                          {sortBy === "urgency" ? (
                            order === "asc" ? (
                              <AddIcon
                                src={SortUpArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <AddIcon
                                src={SortDownArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            )
                          ) : (
                            <AddIcon
                              src={SortDownArrow}
                              style={{ width: "16px", height: "16px" }}
                            />
                          )}
                        </span>
                      )}
                    >
                      Priority
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", width: "10%" }}>
                    <TableSortLabel
                      active={sortBy === "request_date"}
                      direction={sortBy === "request_date" ? order : "asc"}
                      onClick={() => handleSort("request_date")}
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      IconComponent={() => (
                        <span style={{ display: "inline-flex", marginLeft: 4 }}>
                          {sortBy === "request_date" ? (
                            order === "asc" ? (
                              <AddIcon
                                src={SortUpArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <AddIcon
                                src={SortDownArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            )
                          ) : (
                            <AddIcon
                              src={SortDownArrow}
                              style={{ width: "16px", height: "16px" }}
                            />
                          )}
                        </span>
                      )}
                    >
                      Requested At
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "20%", fontWeight: "bold" }}>
                    <TableSortLabel
                      active={sortBy === "comments"}
                      direction={sortBy === "comments" ? order : "asc"}
                      onClick={() => handleSort("comments")}
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      IconComponent={() => (
                        <span style={{ display: "inline-flex", marginLeft: 4 }}>
                          {sortBy === "comments" ? (
                            order === "asc" ? (
                              <AddIcon
                                src={SortUpArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <AddIcon
                                src={SortDownArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            )
                          ) : (
                            <AddIcon
                              src={SortDownArrow}
                              style={{ width: "16px", height: "16px" }}
                            />
                          )}
                        </span>
                      )}
                    >
                      Comments
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  <TableCell style={{ width: "9%", fontWeight: "bold" }}>
                    <TableSortLabel
                      active={sortBy === "time_passed_since_creation"}
                      direction={
                        sortBy === "time_passed_since_creation" ? order : "asc"
                      }
                      onClick={() => handleSort("time_passed_since_creation")}
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      IconComponent={() => (
                        <span style={{ display: "inline-flex", marginLeft: 4 }}>
                          {sortBy === "time_passed_since_creation" ? (
                            order === "asc" ? (
                              <AddIcon
                                src={SortUpArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <AddIcon
                                src={SortDownArrow}
                                style={{ width: "16px", height: "16px" }}
                              />
                            )
                          ) : (
                            <AddIcon
                              src={SortDownArrow}
                              style={{ width: "16px", height: "16px" }}
                            />
                          )}
                        </span>
                      )}
                    >
                      Time
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    style={{ width: "9%", fontWeight: "bold" }}
                  ></TableCell>
                </TableRow>

                {/* Search Row */}
                <TableRow>
                  <TableCell>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search"
                        value={filterByName}
                        onChange={(e) =>
                          handleColumnFilter("name", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddIcon src={ISearch} />{" "}
                              {/* You can replace AddIcon with any other icon */}
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: "14px", // Change the font size to your desired size
                            height: 50,
                          },
                        }}
                      />
                    </Box>
                  </TableCell>

                  <TableCell style={{ width: 4 }}>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search"
                        value={filterByLab}
                        onChange={(e) =>
                          handleColumnFilter("lab", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddIcon src={ISearch} />{" "}
                              {/* You can replace AddIcon with any other icon */}
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: "14px", // Change the font size to your desired size
                            height: 50,
                          },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search"
                        value={filterByUrgency}
                        onChange={(e) =>
                          handleColumnFilter("urgency", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddIcon src={ISearch} />{" "}
                              {/* You can replace AddIcon with any other icon */}
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: "14px", // Change the font size to your desired size
                            height: 50,
                          },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "15%" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "inherit", // Removes the font-size by inheriting it from parent
                        },
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="dd/mm/yyyy"
                          value={filterByDate}
                          onChange={(newDate) =>
                            handleColumnFilter("date", newDate)
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search"
                        value={fileterByComments}
                        onChange={(e) =>
                          handleColumnFilter("comments", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddIcon src={ISearch} />{" "}
                              {/* You can replace AddIcon with any other icon */}
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: "14px", // Change the font size to your desired size
                            height: 50,
                          },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <StatusDropDownStatus
                        handleChange={(e) =>
                          handleColumnFilter("status", e.target.value)
                        }
                        selectedStatus={filterByStatus || ""}
                        dropdownOptions={dropdownOptions}
                        showInfoText={false}
                        sx={{
                          ...getStatusStyles(filterByStatus || "").sx,
                          width: "180px",
                          height: "45px",
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "9%" }}></TableCell>
                  <TableCell style={{ width: "9%" }}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.map((row, index) => (
                  <TableRow
                    key={row.request_id}
                    style={{
                      backgroundColor: "#ffffff", // Alternate row colors
                    }}
                  >
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      {row.patient_name === null ? "-" : row.patient_name}
                    </TableCell>
                    {/* <TableCell>{row.phone_number}</TableCell>
                    <TableCell>{row.email_address}</TableCell> */}
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      {row.requester_name === null ? "-" : row.requester_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      <Button
                        style={{
                          color: "#000",
                          backgroundColor:
                            row.urgency === "urgent"
                              ? "#F5CECE"
                              : row.urgency === "Medium"
                              ? "#FFF5C2"
                              : row.urgency === "routine"
                              ? "#D7EFF7"
                              : "transparent",
                        }}
                      >
                        {row.urgency.charAt(0).toUpperCase() +
                          row.urgency.slice(1)}
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      {formatDate(row.request_date)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      <Tooltip
                        title={row.comments || "No comments available"}
                        placement="top"
                        arrow
                      >
                        <TextField
                          value={row.comments === null ? "-" : row.comments} // Use an empty string when null
                          variant="outlined"
                          fullWidth
                          disabled
                          InputProps={{
                            style: {
                              fontSize: "14px", // Change the font size to your desired size
                              height: 40,
                              border: "none", // Remove all borders
                              borderRadius: 0,
                              borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Add bottom border only
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none", // Disable default borders
                              },
                            },
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      <StatusDropDownStatus
                        handleChange={(e) => {
                          setSelectedRow({
                            request_id: row.request_id,
                            status: e.target.value,
                          });
                          setShowChangeStatus(true); // Open confirmation box
                        }}
                        selectedStatus={row?.status}
                        dropdownOptions={dropdownOptions}
                        showInfoText={false}
                        sx={{
                          ...getStatusStyles(filterByStatus || "").sx,
                          width: "180px",
                          height: "45px",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      {row.time_passed_since_creation === null
                        ? "-"
                        : formatTimeAgo(row.time_passed_since_creation)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none", // Removes the border for this specific cell
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleScanClick(
                            row.study_url,
                            row.request_id,
                            row.requester_name,
                            row.comments,
                            row.request_date,
                            row.status,
                            row.urgency,
                            row.patient_id
                          )
                        }
                        style={{ color: "#000", fontSize: 20 }}
                      >
                        {">"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {isShowChangeStatus && (
            <ConfirmationBox
              title="Are you Sure?"
              description="Do you want to change the status of the request?"
              closeHandler={() => setShowChangeStatus(false)} // Close the confirmation box
              open={isShowChangeStatus}
              onConfirm={() => {
                postTeleRadiologyData(selectedRow.request_id, {
                  status: selectedRow.status,
                });
                setShowChangeStatus(false); // Close the confirmation box after confirming
              }}
            />
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start", // Aligns the pagination controls to the left
          }}
        />
      </Paper>
    </div>
  );
};

export default TeleRadiology;
